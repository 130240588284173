<template>
    <div class="main">
        <div class="main1">
            <div class="incomeCard chatBg">
                <div class="title">聊天收益</div>
                <div class="line"></div>
                <div class="remain">
                    <div class="tip">当前余额</div>
                    <div class="amountAndUnit">
                        <div class="amount">{{ profit.chat_income }}</div>
                        <div class="unit">钻石</div>
                    </div>
                </div>
                <div class="withdrawRate">当前提现比例：1 元={{ profit.chat_income_withdraw_rate }} 钻石</div>
                <div class="btn">
                    <div class="withdrawRecord" @click="seeWithdrawRecord(2)">提现记录</div>
                    <div class="withdraw" @click="applyWithdraw(2)">我要提现</div>
                </div>
            </div>

            <!-- <div class="incomeCard inviteBg">
                <div class="title">邀请收益</div>
                <div class="line"></div>
                <div class="remain">
                    <div class="tip">当前余额</div>
                    <div class="amountAndUnit">
                        <div class="amount">{{ profit.invite_income }}</div>
                        <div class="unit">钻石</div>
                    </div>
                </div>
                <div class="withdrawRate">当前提现比例：1 元={{ profit.invite_income_withdraw_rate }} 钻石</div>
                <div class="btn">
                    <div class="withdrawRecord" @click="seeWithdrawRecord(3)">提现记录</div>
                    <div class="withdraw" @click="applyWithdraw(3)">我要提现</div>
                </div>
            </div> -->

            <div class="tipsMain">
                <div class="tips">提现说明</div>
                <div class="desc">1、用户必须完成实名认证，并绑定本人真实有效的支付宝账号（与实名认证一致）才可申请提现，因提供虚假认证或个人原因填错提现账号造成的经济纠纷或问题，平台不承担任何责任。</div>
                <div class="desc">2、正常提现一般会在次日支付到您绑定的支付宝账号，请注意查收，实际到账时间以支付宝到账时间为准。</div>
                <div class="desc">3、用户发起的提现申请，平台将代收7个点的服务费。</div>
            </div>
        </div>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
import { Toast } from 'vant'

export default {
    data() {
        return {
            profit: {},
        }
    },
//     1、用户必须完成实名认证，并绑定本人真实有效的支付宝账号
// （与实名认证一致）才可申请提现，因提供虚假认证或个人原
// 因填错提现账号造成的经济纠纷或问题，平台不承担任何责任。
// 2、正常提现一般会在下个工作日支付到您绑定的支付宝账号，
// 周末（含周五）及节假日顺延至下个工作日，支付后会有通
// 知提醒，请注意查收，实际到账时间以支付宝到账时间为准。
// 3、用户发起的提现申请，平台将代收7个点的个人所得税。
    methods: {
        seeWithdrawRecord(currency) {
            let query = this.$route.query
            query.currency = currency
            this.$router.push({
                path: '/withdraw/record',
                query,
            })
        },
        applyWithdraw(currency) {
            if (this.profit.real_name != 1) {
                Toast.fail('请先完成实名认证')
                return
            }
            let query = this.$route.query
            query.currency = currency
            this.$router.push({
                path: '/withdraw/apply',
                query,
            })
        },
        initData() {
            doRequest('/account/profit', {}, this.$route.query).then(res => {
                this.profit = res
            })
        },
    },
    mounted() {
        this.initData()
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.main1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 375px;
}

.chatBg {
    margin-top: 5px;
    background: url('../../assets/my_chat_income_card.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.inviteBg {
    margin-top: 25px;
    background: url('../../assets/my_invite_income_card.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.incomeCard {
    width: 351px;
    height: 165px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.incomeCard .title {
    margin-top: 11px;
    margin-left: 16px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.incomeCard .line {
    margin-top: 10px;
    margin-left: 9px;
    width: 332px;
    height: 1px;
    background: #FFFFFF;
    opacity: 0.5;
    border-radius: 1px;
}

.incomeCard .remain {
    width: 328px;
    height: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    margin-top: 20px;
}

.incomeCard .remain .tip {
    height: 15px;
    line-height: 15px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.incomeCard .remain .amountAndUnit {
    display: flex;
    height: 21px;
    align-items: flex-end;
}

.incomeCard .remain .amountAndUnit .amount {
    height: 21px;
    line-height: 21px;
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.incomeCard .remain .amountAndUnit .unit {
    height: 12px;
    line-height: 12px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 5px;
}

.incomeCard .withdrawRate {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 16px;
    margin-top: 22px;
}

.incomeCard .btn {
    width: 328px;
    height: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
    margin-top: 10px;
}

.incomeCard .btn .withdrawRecord {
    width: 120px;
    height: 35px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid #FFFFFF;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.incomeCard .btn .withdraw {
    width: 120px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.tipsMain {
    display: flex;
    flex-direction: column;
    width: 375px;
}

.tips {
    margin-left: 12px;
    margin-top: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.desc {
    width: 351px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 25px;

    margin-left: 12px;
    margin-top: 25px;
}
</style>